import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { withStyles } from "tss-react/mui";
import { Component } from "react";
import { PropTypes } from "prop-types";
import SideBar from "../components/SideBar";
import Live from "./Live";
import Events from "./Events";
import axios from "axios";
import { getUserEmail } from "../utils/tokenUtils";
import Localisation from "./Localisation";
import DiceUpload from "./DiceUpload";
import ClubDpsUpload from "./ClubDpsUpload";
import Notification from "./Notification";
import UserRewards from "./UserRewards";
import Cohort from "./Cohort";
import BoardUpload from "./BoardUpload";
import TokenUpload from "./TokenUpload";
import FrameUpload from "./FrameUpload";
import AvatarUpload from "./AvatarUpload";
import GetConfig from "./GetConfig";
import SeasonUpload from "./SeasonUpload";
import DynamicLink from "./DynamicLink";
import BundleUpload from "./CosmeticBundleUpload";
import RBSeasonUpload from "./RewardBoxSeasonUpload";
import LeaderboardReward from "./club/leaderboard/ClubLeaderboardRewardScheduler";
import Wrapper from "../components/Wrapper";
import CosmeticAssetUpload from "./club/cosmetic/ClubCosmeticAssetUpload";

import ClubsEventsUpload from "./ClubsEventsUpload";
import ClubEventsWebLink from "./ClubEventsWebLink";
import ConfigUploader from "./ConfigUploader";
import { useDashboardStore } from "../store/dashboard.store";
import ABExperimentation from "./abExperimentation/ABExperimentation";
import CardCollectionUpload from "./CardCollectionUpload";

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of MemberExpression.
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
});

class Dashboard extends Component {
  componentDidMount = () => {
    this.getLiveInfo(this.props.state.currentLiveServer);
    this.getCohortData(1);
    this.getUserInfo();
    this.getCurrentDiceID();
    this.getCurrentTokenID();
    this.getCurrentFrameID();
    this.getCurrentAvatarID();
    this.getEventDrafts();
  };

  getEventDrafts = () => {
    this.props.setState({ eventLoading: true });
    axios
      .get("/api/event/allEventDrafts")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.props.setState({ eventLoading: false });
        } else {
          this.props.setState({
            eventDrafts: data,
            eventLoading: false,
          });
        }
      });
  };

  getCohortData = (server) => {
    this.props.setState({ cohortLoading: true });
    axios
      .get("/api/getCohort/server/" + server)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.props.setState({ cohortLoading: false });
        } else {
          this.props.setState({
            cohortData: data["CD"]["cohort_config"],
            cohortLoading: false,
          });
        }
      });
  };

  getUserInfo = () => {
    this.props.setState({ userLoading: true });
    var email = getUserEmail();
    axios
      .get("/api/user/email/" + email)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.props.setState({ userLoading: false });
        } else {
          this.props.setState({ user: data, userLoading: false });
        }
      })
      .catch((err) => {
        this.props.setState({ userLoading: false });
      });
  };

  getLiveInfo = (server) => {
    this.props.setState({ liveLoading: true });
    axios
      .get("/api/liveinfo/server/" + server)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.props.setState({ liveLoading: false });
        } else {
          this.props.setState({
            liveInfo: data,
            nextVersion: data["NV"],
            liveLoading: false,
          });
        }
      })
      .catch((err) => {
        this.props.setState({ liveLoading: false });
      });
  };

  getCurrentDiceID = (server) => {
    axios
      .get("/api/diceinfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          var diceIDs = data.payload.split("|");
          this.props.setState({ eventDiceID: diceIDs[0] });
          this.props.setState({ cosmeticDiceID: diceIDs[1] });
          this.props.setState({ rewardBoxDiceID: diceIDs[2] });
        }
      })
      .catch((err) => { });
  };

  getCurrentTokenID = (server) => {
    axios
      .get("/api/tokeninfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          this.props.setState({ tokenID: data.payload });
        }
      })
      .catch((err) => { });
  };

  getCurrentFrameID = (server) => {
    axios
      .get("/api/frameinfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          this.props.setState({ frameID: data.payload });
        }
      })
      .catch((err) => { });
  };

  getCurrentAvatarID = (server) => {
    axios
      .get("/api/avatarinfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          this.props.setState({ avatarID: data.payload });
        }
      })
      .catch((err) => { });
  };

  addEventDraft = (eventName, eventType) => {
    this.props.setState({ userLoading: true });
    axios
      .get("/api/addEvent/name/" + eventName + "/type/" + eventType)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.props.setState({ userLoading: false });
        } else {
          this.props.setState({ eventDrafts: data, userLoading: false });
        }
      })
      .catch((err) => {
        this.props.setState({ userLoading: false });
      });
  };

  removeEventDraft = (eventID) => {
    this.props.setState({ userLoading: true });
    axios
      .delete("/api/removeEvent/eventID/" + eventID)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          this.props.setState({ userLoading: false });
        } else {
          this.props.setState({ userLoading: false, eventDrafts: data });
        }
      })
      .catch((err) => {
        this.props.setState({ userLoading: false });
      });
  };

  setUserData = (userData) => {
    this.props.setState({ user: userData });
  };

  addFile = (eventID, type, file) => {
    let state = Object.assign({}, this.props.state);
    var index = 0;
    for (var eve of state.eventDrafts) {
      if (eve.ID === eventID) {
        break;
      }
      index++;
    }
    if (type === "main") {
      const indexToRemove = state.eventDrafts[index].MainFiles.indexOf(file);
      if (indexToRemove == -1) {
        state.eventDrafts[index].MainFiles.push(file);
      }
    } else {
      const indexToRemove = state.eventDrafts[index].BackgroundFiles.indexOf(file);
      if (indexToRemove == -1) {
        state.eventDrafts[index].BackgroundFiles.push(file);
      }
    }
    this.props.setState(state);
  };

  removeFile = (eventID, type, fileName) => {
    let state = Object.assign({}, this.props.state);
    var index = 0;
    for (var eve of state.eventDrafts) {
      if (eve.ID === eventID) {
        break;
      }
      index++;
    }
    if (type === "main") {
      const indexToRemove = state.eventDrafts[index].MainFiles.indexOf(fileName);
      if (indexToRemove !== -1) {
        state.eventDrafts[index].MainFiles.splice(indexToRemove, 1);
      }
    } else {
      const indexToRemove = state.eventDrafts[index].BackgroundFiles.indexOf(fileName);
      if (indexToRemove !== -1) {
        state.eventDrafts[index].BackgroundFiles.splice(indexToRemove, 1);
      }
    }
    this.props.setState(state);
  };

  updateEvent = (event) => {
    let state = Object.assign({}, this.props.state);
    var index = 0;
    for (var eve of state.eventDrafts) {
      if (eve.ID === event.ID) {
        break;
      }
      index++;
    }
    state.eventDrafts[index].EventData = event.EventData;
    this.props.setState(state);
  };

  updateNotif = (fileName) => {
    let state = Object.assign({}, this.props.state);
    state.user.Notifications.FileName = fileName;
    this.props.setState(state);
  };

  updateUserRewards = (fileName) => {
    let state = Object.assign({}, this.props.state);
    state.user.UserRewards.FileName = fileName;
    this.props.setState(state);
  };

  getCurrentView = () => {
    switch (this.props.state.currentView) {
      case "live":
        return (
          <Live
            currentLiveServer={this.props.state.currentLiveServer}
            handleLiveInfoChange={this.handleLiveInfoChange}
            liveInfo={this.props.state.liveInfo}
            liveLoading={this.props.state.liveLoading}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "events":
        return (
          <Events
            user={this.props.state.user}
            eventDrafts={this.props.state.eventDrafts}
            setUserData={this.setUserData}
            addFile={this.addFile}
            removeFile={this.removeFile}
            userLoading={this.props.state.userLoading}
            removeEventDraft={this.removeEventDraft}
            addEventDraft={this.addEventDraft}
            updateEvent={this.updateEvent}
            liveInfo={this.props.state.liveInfo}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "clubLeaderboardRewardsScheduler":
        return (
          <Wrapper
            heading="Club Leaderboard Rewards Scheduler"
            setIsLoggedIn={this.props.setIsLoggedIn}
          >
            <LeaderboardReward />
          </Wrapper>
        );

      case "locale":
        return (
          <Localisation
            nextVersion={this.props.state.nextVersion}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "diceUpload":
        return (
          <DiceUpload
            diceID={parseInt(this.props.state.diceID) + 1}
            eventDiceID={parseInt(this.props.state.eventDiceID) + 1}
            cosmeticDiceID={parseInt(this.props.state.cosmeticDiceID) + 1}
            rewardBoxDiceID={parseInt(this.props.state.rewardBoxDiceID) + 1}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "clubdpUpload":
        return <ClubDpsUpload setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "clubEventsUpload":
        return <ClubsEventsUpload setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "clubEventsWebLinkUpload":
        return <ClubEventsWebLink setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "boardUpload":
        return <BoardUpload setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "tokenUpload":
        return (
          <TokenUpload
            tokenID={parseInt(this.props.state.tokenID) + 1}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "frameUpload":
        return (
          <FrameUpload
            frameID={parseInt(this.props.state.frameID) + 1}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "avatarUpload":
        return (
          <AvatarUpload
            avatarID={parseInt(this.props.state.avatarID) + 1}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "seasonUpload":
        return <SeasonUpload setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "dynamicLink":
        return <DynamicLink setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "getConfig":
        return (
          <GetConfig
            currentLiveServer={this.props.state.currentLiveServer}
            handleLiveInfoChange={this.handleLiveInfoChange}
            liveInfo={this.props.state.liveInfo}
            liveLoading={this.props.state.liveLoading}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "pushNotification":
        return (
          <Notification
            user={this.props.state.user}
            updateNotif={this.updateNotif}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );

      case "userRewards":
        return (
          <UserRewards
            user={this.props.state.user}
            updateUserRewards={this.updateUserRewards}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "cohortConfig":
        return (
          <Cohort
            liveServer={this.props.state.currentLiveServer}
            handleLiveInfoChange={this.handleLiveInfoChange}
            cohortData={this.props.state.cohortData}
            cohortLoading={this.props.state.cohortLoading}
            getCohortData={this.getCohortData}
            setIsLoggedIn={this.props.setIsLoggedIn}
          />
        );
      case "cosmeticBundle":
        return <BundleUpload setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "rewardBoxSeason":
        return <RBSeasonUpload setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "cosmeticAssetsUpload":
        return <CosmeticAssetUpload setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "configUpload":
        return <ConfigUploader />;
      case "abExperimentation":
        return <ABExperimentation setIsLoggedIn={this.props.setIsLoggedIn} />;
      case "cardCollection":
        return <CardCollectionUpload />
      default:
        return "";
    }
  };

  handleLiveInfoChange = (event) => {
    this.props.setState({ currentLiveServer: event.target.value });
    this.getLiveInfo(event.target.value);
  };

  setCurrentView = (view) => {
    this.props.setState({ currentView: view });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <SideBar
          setCurrentView={this.setCurrentView}
          currentView={this.props.state.currentView}
        />
        <main className={classes.content}>{this.getCurrentView()}</main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const withStore = (BaseComponent) => (props) => {
  const store = useDashboardStore();
  return (
    <BaseComponent
      {...props}
      state={store}
      setState={useDashboardStore.setState}
    />
  );
};

export default withStore(withStyles(Dashboard, useStyles));
