import React, { useState, useEffect } from "react";
import Wrapper from "../components/Wrapper";
import { withStyles } from "tss-react/mui";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { PropTypes } from "prop-types";
import RewardDropDown from "../components/RewardDropDown";
import DropBox from "../components/DropBox";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  container: {
    margin: "5vh 10vw",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const CardCollectionUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rewardData, setRewardData] = useState({
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
    8: {},
    9: {},
    10: {},
    11: {},
    12: {}
  });
  const [cardCollectionNo, setcardCollectionNo] = useState(0);
  const [configNo, setConfigNo] = useState(0);
  const [currServer, setCurrServer] = useState(0);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [nextStartTime, setNextStartTime] = useState(new Date());

  useEffect(() => {
    getCardCollectionInfo();
  }, []);

  const getCardCollectionInfo = () => {
    setLoading(true);
    axios
      .get("/api/getProdCardCollectionInfo")
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
        } else {
          setConfigNo(data["CN"]);
          setSeasonNo(data["CCN"]);
        }
        setLoading(false);
      });
  };

  // Validate if all the fields are filled before publishing
  const checkValidity = (seasonData) => {
    let rewardData = seasonData["rewardData"];
    for (let i in rewardData) {
      if (Object.keys(rewardData[i]).length == 0) {
        return false;
      }
    }
    if (files.length <= 0) {
      return false;
    }
    let startTime = seasonData["startDate"];
    let endTime = seasonData["endDate"];
    if (
      startTime == Date() ||
      endTime == Date() ||
      nextStartTime == Date()
    ) {
      return false;
    }
    return true;
  };

  const publishCardCollection = (data) => {
    let seasonData = {
      reward_data: data,
      start_time: startTime,
      end_time: endTime,
      next_start_time: nextStartTime,
    };
    let isDataValid = checkValidity(seasonData);
    if (!isDataValid) {
      alert(
        "MISSING FIELDS. All the fields are mandatory. \nPlease check if you have uploaded both the main files and background files. \nPlease check if you have updated rewards.",
      );
      return;
    }
    setLoading(true);
    let url = `/api/publishCardCollection/dev/${currServer}/cardCollection/${cardCollectionNo}/${configNo}`;
    axios
      .post(url, seasonData)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("Publish Failed: " + data.err, {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("Publish Successful", {
            variant: "success",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("Publish Failed", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const addFile = (file, type) => {
    setFiles((current) => [...current, file]);
  };

  const uploadSeasonAssets = (file) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    let url = `/api/file/cardCollectionAsset/${cardCollectionNo}`;
    axios
      .post(url, data)
      .then((json) => {
        return json.data;
      })
      .then((data) => {
        if (data.err) {
          const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
            variant: "error",
          });
        } else {
          const key = enqueueSnackbar("File - " + file.name + " uploaded", {
            variant: "success",
          });
          addFile(data, type);
        }
        setLoading(false);
      })
      .catch((err) => {
        const key = enqueueSnackbar("File - " + file.name + " not uploaded", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const handleDropFile = (fileList, type) => {
    for (var file of fileList) {
      uploadSeasonAssets(file, type);
    }
  };

  const collectionRewards = () => {
    let tierData = [];
    for (let i in rewardData) {
      tierData.push(
        <Grid item xs={6}>
          <RewardDropDown
            header={"Collection : " + i}
            tier={i}
            rewardData={rewardData}
            setReward={setRewardData}
          />
        </Grid>,
      );
    }
    return tierData;
  };

  const { classes } = props;

  return (
    <div className={classes.root}>
      <Wrapper heading="CardCollection Upload" setIsLoggedIn={props.setIsLoggedIn}>
        <div style={{ margin: "5vh 10vw" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={3}>
              <TextField
                id="outlined-basic"
                label="Card Collection Number:"
                value={cardCollectionNo}
                onChange={(event) => {
                  setSeasonNo(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="outlined-basic"
                label="Card Collection Config Number:"
                value={configNo}
                onChange={(event) => {
                  setConfigNo(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="server-select"
                  id="server-select"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={currServer}
                  onChange={(event) => {
                    setCurrServer(event.target.value);
                  }}
                >
                  <MenuItem value={0}>Dev</MenuItem>
                  <MenuItem value={1}>Production</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => {
                  publishCardCollection(rewardData);
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                size="large"
                disabled={loading}
              >
                Publish
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"Start Date"}
                type="datetime-local"
                value={startTime}
                className={classes.textField}
                onChange={(event) => {
                  setStartTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"End Date"}
                type="datetime-local"
                value={endTime}
                className={classes.textField}
                onChange={(event) => {
                  setEndTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label={"Next Start Date"}
                type="datetime-local"
                value={nextStartTime}
                className={classes.textField}
                onChange={(event) => {
                  setNextStartTime(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Card Collection Assets: </h2>
              <DropBox
                type="main"
                uploadedFiles={files}
                loading={loading}
                handleDropFile={handleDropFile}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Rewards: </h2>
              <div className={classes.header}>
                <Grid container spacing={3}>
                  {collectionRewards()}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Wrapper>
    </div>
  );
};

CardCollectionUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(CardCollectionUpload, useStyles);
